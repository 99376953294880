.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.join-wrapper {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #00092a;
  background-image: url(//mktv-in-cdn.mockuai.com/16285640141343353.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.join-wrapper .join-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 960px;
  padding-top: 3.69791667vw;
}
.join-wrapper h2.title {
  margin-bottom: 2.86458333vw;
  text-align: center;
  font-size: 2.083333vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
.join-wrapper .join-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 9.375vw;
}
.join-wrapper .join-box .join-item {
  width: 40.1046667vw;
  height: 12.39583333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 0.975609756097561vw;
  color: #fff;
  background-color: #001357;
  background-size: cover;
  background-repeat: no-repeat;
}
.join-wrapper .join-box .join-item:nth-child(1) {
  background-image: url(//mktv-in-cdn.mockuai.com/16286502266941067.png);
}
.join-wrapper .join-box .join-item:nth-child(1):hover {
  background-image: url(https://mktv-in-cdn.mockuai.com/16250520741369690.png);
}
.join-wrapper .join-box .join-item:nth-child(2) {
  background-image: url(//mktv-in-cdn.mockuai.com/16275393552296636.png);
}
.join-wrapper .join-box .join-item:nth-child(2):hover {
  background-image: url(https://mktv-in-cdn.mockuai.com/16275394627109337.png);
}
.join-wrapper .join-box .join-item:last-child {
  margin-right: 0;
}
.join-wrapper .join-box .join-item .text {
  margin-bottom: 0.36458333vw;
  line-height: 2.916667vw;
  font-size: 2.083333vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.join-wrapper .join-box .join-item .line {
  width: 1.6667vw;
  height: 0.208333vw;
  background-color: #fff;
}
.join-wrapper .footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 47px;
}
@media screen and (max-width: 960px) {
  .join-wrapper .join-container {
    padding-top: 32px;
  }
  .join-wrapper h2.title {
    margin-bottom: 27.5px;
    font-size: 20px;
  }
  .join-wrapper .join-box {
    width: 770px;
    margin: 0 auto;
  }
  .join-wrapper .join-box .join-item {
    width: 379.65277778px;
    height: 119px;
  }
  .join-wrapper .join-box .join-item .text {
    font-size: 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1440px) {
  .join-wrapper .join-box .join-item {
    width: 40.97222222vw;
    margin-right: 1.38888889vw;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1640px) {
  .join-wrapper .join-box {
    width: 1200px;
    margin: 0 auto;
  }
  .join-wrapper .join-box .join-item {
    width: 590px;
    height: 238px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 1640px) {
  .join-wrapper h2.title {
    font-size: 40px;
  }
  .join-wrapper .join-box {
    width: 1440px;
    margin: 0 auto;
  }
  .join-wrapper .join-box .join-item {
    width: 710px;
    height: 238px;
  }
  .join-wrapper .join-box .join-item .text {
    font-size: 40px;
  }
}
