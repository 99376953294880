.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes media-fade-in {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-in-animation {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}
.media-fade-in {
  animation: media-fade-in 0.8s ease 0s 1 normal both;
}
.fade-in-animation {
  animation: fade-in-animation 0.4s ease 80ms 1 normal both;
}
.data-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.09756098%;
  background-size: cover;
  background-repeat: no-repeat;
}
.data-wrapper.wrap-saas {
  background-image: url(https://mktv-in-cdn.mockuai.com/16286534913535319.png);
}
.data-wrapper.wrap-supply {
  background-image: url(https://mktv-in-cdn.mockuai.com/16238152069838910.png);
}
.data-wrapper.wrap-anchor {
  background-image: url(https://mktv-in-cdn.mockuai.com/16238149059689781.png);
}
.data-wrapper.wrap-data {
  background-image: url(https://mktv-in-cdn.mockuai.com/16238154825288660.png);
}
h2.title {
  text-align: center;
  line-height: 1.4;
  padding-top: 4.375%;
  margin-bottom: 0.6097561%;
  font-size: 2.19512195vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
h5.subtitle {
  margin-bottom: 1.34146341%;
  text-align: center;
  line-height: 1.4;
  font-size: 1.09756098vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
}
.content-box {
  position: relative;
  height: 69.13580247%;
  border-radius: 16px;
  overflow: hidden;
  background-color: #001357;
  display: flex;
}
.content-box .content-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.content-box .content-bg.bg-saas {
  background-image: url(https://mktv-in-cdn.mockuai.com/16277238664112404.png);
}
.content-box .content-bg.bg-supply {
  background-image: url(https://mktv-in-cdn.mockuai.com/16309805239156737.png);
}
.content-box .content-bg.bg-anchor {
  background-image: url(https://mktv-in-cdn.mockuai.com/16277240252572093.png);
}
.content-box .content-bg.bg-data {
  background-image: url(https://mktv-in-cdn.mockuai.com/16277247505809411.png);
}
.content-box .menu {
  width: 13.88888889%;
}
.content-box .menu .menu-item {
  position: relative;
  box-sizing: border-box;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(0, 9, 42, 0.1);
}
.content-box .menu .menu-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}
.content-box .menu .menu-item:last-child::after {
  content: '';
  width: 0;
  height: 0;
}
.content-box .menu .menu-item.active {
  background-color: rgba(255, 102, 0, 0.7);
  border-radius: 0;
}
.content-box .menu .menu-item.active:first-child {
  border-top-left-radius: 8px;
}
.content-box .menu .menu-item.active:last-child {
  border-bottom-left-radius: 8px;
}
.content-box .menu .menu-item.active::after {
  content: '';
  width: 0;
  height: 0;
}
.content-box .menu .menu-item .icon {
  width: 2.9268292682926833vw;
  height: 2.9268292682926833vw;
  margin-bottom: 0.3658536585365854vw;
}
.content-box .menu .menu-item .text {
  text-align: center;
  white-space: pre-wrap;
  line-height: 1.4;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 1.09756098vw;
  font-weight: 400;
  color: #fff;
}
.content-box .data-box {
  display: flex;
  flex-direction: column;
  margin-left: 4.02777778%;
  padding-top: 9.20731707vw;
  color: #fff;
}
.content-box .data-box .desc {
  margin-bottom: 4.45121951vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-size: 1.82926829vw;
  font-weight: 600;
  animation: fade-in-animation 0.4s ease 80ms 1 normal both;
}
.content-box .data-box .data-items {
  display: flex;
  align-items: center;
  animation: fade-in-animation 0.4s ease 80ms 1 normal both;
}
.content-box .data-box .data-items .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2.43902439vw;
  font-size: 1.0416667vw;
  font-weight: 400;
}
.content-box .data-box .data-items .data-item:last-child {
  margin-right: 0;
}
.content-box .data-box .data-items .data-item .num-line {
  display: flex;
  align-items: flex-end;
}
.content-box .data-box .data-items .data-item .num-line .num {
  line-height: 1.14705882;
  font-size: 4.14634146vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
}
.content-box .data-box .data-items .data-item .num-line .unit,
.content-box .data-box .data-items .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.2195122vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
}
.content-box .data-box .data-items .data-item .num-line .unit {
  margin-bottom: 0.6097561vw;
}
.content-box .data-box .data-items .data-item .num-line .plus {
  font-weight: 100;
  align-self: center;
}
.content-box .data-box .data-items .data-item .text-line {
  line-height: 1.4;
  font-size: 1.2195122vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  opacity: 0.8;
}
.content-box .banner {
  position: absolute;
}
.content-box .banner.banner-saas {
  right: 3.33333333%;
  bottom: 0;
  width: 39.16666667%;
}
.content-box .banner.banner-supply {
  right: 3.19444444%;
  bottom: 8.39285714%;
  width: 40.06944444%;
}
.content-box .banner.banner-anchor {
  right: 2.84722222%;
  bottom: 8.03571429%;
  width: 37.22222222%;
}
.content-box .banner.banner-data {
  right: 3.05555556%;
  bottom: 0;
  width: 33.61111111%;
}
@media screen and (min-width: 1640px) {
  h2.title {
    padding-top: 63px;
    margin-bottom: 10px;
    font-size: 36px;
  }
  h5.subtitle {
    margin-bottom: 22px;
    font-size: 18px;
  }
  .content-box.bg-saas .data-box .data-items .data-item {
    margin-right: 40px;
    width: 120px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item {
    margin-right: 40px;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(1) {
    width: 103px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(2) {
    width: 180px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(3) {
    width: 100px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item {
    margin-right: 40px;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(1) {
    width: 160px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(2) {
    width: 160px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(3) {
    width: 166px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item {
    margin-right: 40px;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(1) {
    width: 172px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(2) {
    width: 180px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(3) {
    width: 120px !important;
  }
  .content-box .menu .menu-item .text {
    font-size: 18px;
  }
  .content-box .data-box {
    padding-top: 151px;
  }
  .content-box .data-box .desc {
    margin-bottom: 73px;
    font-size: 30px;
  }
  .content-box .data-box .data-items .data-item .num-line .num {
    font-size: 68px;
  }
  .content-box .data-box .data-items .data-item .num-line .unit,
  .content-box .data-box .data-items .data-item .num-line .plus {
    font-size: 20px;
  }
  .content-box .data-box .data-items .data-item .num-line .unit {
    margin-bottom: 10px;
  }
  .content-box .data-box .data-items .data-item .text-line {
    font-size: 20px;
  }
}
@media screen and (max-width: 960px) {
  h2.title {
    padding-top: 36.875px;
    margin-bottom: 5.125px;
    font-size: 21.0732px;
  }
  h5.subtitle {
    margin-bottom: 11.2969px;
    font-size: 10.5366px;
  }
  .content-box.bg-saas .data-box .data-items .data-item {
    margin-right: 23.4146px;
    width: 70.234px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item {
    margin-right: 23.4146px;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(1) {
    width: 60.281px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(2) {
    width: 105.359px !important;
  }
  .content-box.bg-supply .data-box .data-items .data-item:nth-child(3) {
    width: 58.531px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item {
    margin-right: 23.4146px;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(1) {
    width: 93.656px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(2) {
    width: 93.656px !important;
  }
  .content-box.bg-anchor .data-box .data-items .data-item:nth-child(3) {
    width: 97.156px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item {
    margin-right: 23.4146px;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(1) {
    width: 100.672px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(2) {
    width: 105.359px !important;
  }
  .content-box.bg-data .data-box .data-items .data-item:nth-child(3) {
    width: 70.234px !important;
  }
  .content-box .menu .menu-item .text {
    font-size: 10.5366px;
  }
  .content-box .data-box {
    padding-top: 88.3902px;
  }
  .content-box .data-box .desc {
    margin-bottom: 42.732px;
    font-size: 17.561px;
  }
  .content-box .data-box .data-items .data-item .num-line .num {
    font-size: 39.8049px;
  }
  .content-box .data-box .data-items .data-item .num-line .unit,
  .content-box .data-box .data-items .data-item .num-line .plus {
    font-size: 11.7073px;
  }
  .content-box .data-box .data-items .data-item .num-line .unit {
    margin-bottom: 5.85366px;
  }
  .content-box .data-box .data-items .data-item .text-line {
    font-size: 11.7073px;
  }
}
