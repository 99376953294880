.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.video-section {
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  padding-top: 100px;
  background-color: rgba(0, 9, 42, 0.3);
}
.video-section .company-introduction {
  line-height: 26px;
  width: 48.5vw;
  color: #FFF;
  font-size: 15px;
}
.video-section .company-introduction p {
  text-indent: 2em;
}
.video-section .video-bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-section .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-section h1.title {
  width: 80vw;
  margin: 0 auto 2.68292683vw;
  font-size: 4.634146341463414vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: normal;
  color: #fff;
  text-align: center;
}
.video-section .role-entrance {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 50vw;
  border-radius: 0.4166667vw;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
}
.video-section .role-entrance:hover {
  border: none;
}
.video-section .role-entrance:hover .role-item::after {
  content: "";
  width: 0;
}
.video-section .role-entrance .role-item {
  position: relative;
  box-sizing: border-box;
  height: 6.25vw;
  padding-left: 2.083333vw;
  padding-right: 2.36111vw;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4166667vw;
  cursor: pointer;
}
.video-section .role-entrance .role-item:hover {
  background-color: rgba(255, 128, 0, 0.8);
}
.video-section .role-entrance .role-item::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 4.166664vw;
  background-color: #fff;
  opacity: 0.4;
}
.video-section .role-entrance .role-item:last-child::after {
  content: "";
  width: 0;
  height: 0;
}
.video-section .role-entrance .role-item .info {
  color: #fff;
}
.video-section .role-entrance .role-item .info .title {
  margin-bottom: 0.138888vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 1.5625vw;
  font-weight: 500;
}
.video-section .role-entrance .role-item .info .subtitle {
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 0.833333vw;
  font-weight: 400;
}
.video-section .role-entrance .role-item .icon {
  width: 1.40625vw;
  height: 1.40625vw;
}
.video-section .news-wrapper {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 9, 42, 0.3);
}
.video-section .news-wrapper .news-container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 100px;
  margin: 0 auto;
}
.video-section .news-wrapper .title {
  width: 3.75vw;
  height: 1.6145833333333335vw;
  margin-right: 47px;
  background-image: url(https://mktv-in-cdn.mockuai.com/16391249997612189.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.video-section .news-wrapper .news-list {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 0;
  flex: 1;
}
.video-section .news-wrapper .news-list .swiper-slide {
  height: 25px;
}
.video-section .news-wrapper .news-list .news-swiper {
  height: 100%;
}
.video-section .news-wrapper .news-list .news-swiper .swiper-slide {
  display: flex;
  align-items: center;
}
.video-section .news-wrapper .news-list .news-item {
  margin-bottom: 0.902777vw;
}
.video-section .news-wrapper .news-list .news-item:last-child {
  margin-bottom: 0;
}
.video-section .news-wrapper .news-list .news-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
}
.video-section .news-wrapper .more {
  display: block;
  text-decoration: none;
  width: 88px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-size: 14px;
  cursor: pointer;
}
.video-section .news-wrapper .more:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #333;
  font-weight: 500;
}
@media screen and (min-width: 1640px) {
  .video-section h1.title {
    width: 1200px;
    font-size: 70px;
  }
  .video-section .company-introduction {
    width: 930px;
  }
  .video-section .role-entrance {
    width: 960px;
  }
  .video-section .role-entrance .role-item {
    height: 120px;
  }
  .video-section .role-entrance .role-item .info .title {
    font-size: 30px;
  }
  .video-section .role-entrance .role-item .info .subtitle {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .video-section h1.title {
    width: 750px;
    font-size: 50px;
  }
  .video-section .company-introduction {
    width: 570px;
  }
  .video-section .role-entrance {
    width: 600px;
  }
  .video-section .role-entrance .role-item {
    height: 77px;
  }
  .video-section .role-entrance .role-item .info .title {
    font-size: 18.75px;
  }
  .video-section .role-entrance .role-item .info .subtitle {
    font-size: 10px;
  }
}
