




















































.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  // transition: all 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  // transform: translateX(100%)
}
.fade-enter-to, .fade-leave {
  transform: translate(-50%, -50%);
}
.consult-modal {
  box-sizing: border-box;
  position: fixed;
  z-index: 1200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 438px;
  height: 174px;
  padding: 26px 30px 30px;
  background-color: rgba(255, 255, 255, .9);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .close-icon {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
  .title {
    margin-bottom: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px
  }
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .option-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 8px;
      background-color: rgba(255, 102, 0, .05);
      cursor: pointer;
      &:hover {
        border: 1px solid rgba(255, 102, 23, 0.19);
      }
      .icon {
        width: 32px;
        height: 32px;
        margin-bottom: 3px;
        object-fit: cover;
        // &:nth-child(1) {
        //   width: 24px;
        //   height: 25px;
        // }
        // &:nth-child(2) {
        //   width: 24px;
        //   height: 28px;
        // }
        // &:nth-child(3) {
        //   width: 24px;
        //   height: 25px;
        // }
        // &:nth-child(4) {
        //   width: 23px;
        //   height: 24px;
        // }
      }
      .text {
        margin-top: 2px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
