.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.panels-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.09756098%;
}
h2.title {
  padding-top: 4.375%;
  margin: 0 auto 0.55555556%;
  text-align: center;
  color: #000;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  font-size: 2.19512195vw;
}
h5.subtitle {
  margin-bottom: 1.66666667%;
  text-align: center;
  color: #666;
  font-size: 1.09756098vw;
  font-weight: 400;
}
.panels {
  display: flex;
  align-items: center;
}
.panels .panel-item {
  box-sizing: border-box;
  transition: all 0.35s;
  position: relative;
  flex: 1;
  width: 18.29268292682927vw;
  height: 34.146341463414636vw;
  margin-right: 1.2195121951219512vw;
  border-radius: 0.975609756097561vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #001357;
}
.panels .panel-item:nth-child(1) {
  background-image: url(https://mktv-in-cdn.mockuai.com/16249673371487950.png);
}
.panels .panel-item:nth-child(2) {
  background-image: url(https://mktv-in-cdn.mockuai.com/16237607418287684.png);
}
.panels .panel-item:last-child {
  margin-right: 0;
}
.panels .panel-item.active {
  width: 68.29268292682927vw;
}
.panels .panel-item.active:nth-child(1) {
  background-image: url(https://mktv-in-cdn.mockuai.com/16286505801276166.png);
}
.panels .panel-item.active:nth-child(2) {
  background-image: url(https://mktv-in-cdn.mockuai.com/16237606426209129.png);
}
.panels .panel-item.active:hover {
  background-color: #001357;
}
.panels .panel-item .role {
  position: absolute;
  left: 1.768292682926829vw;
  bottom: 1.2804878048780488vw;
  line-height: 1.4;
  font-size: 2.195121951219512vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
.panels .panel-item .content {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 2.01219512vw 1.82926829vw 0;
}
.panels .panel-item .content.fade-enter-active,
.panels .panel-item .content .fade-leave-active {
  transition: opacity 1s;
}
.panels .panel-item .content.fade-enter,
.panels .panel-item .content .fade-leave-to {
  opacity: 0;
}
.panels .panel-item .content .title {
  margin-bottom: 2.1341463414634148vw;
  line-height: 1.4;
  font-size: 1.8292682926829267vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
.panels .panel-item .content .functions {
  display: flex;
  flex-wrap: wrap;
  height: 21.951219512195124vw;
  box-sizing: border-box;
}
.panels .panel-item .content .functions .function-item {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 32.0754717%;
  margin-right: 1.88679245%;
  margin-bottom: 1.88679245%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.4878048780487805vw;
  border: 1px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
}
.panels .panel-item .content .functions .function-item:nth-child(3n) {
  margin-right: 0;
}
.panels .panel-item .content .functions .function-item:hover {
  background-color: rgba(255, 102, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.panels .panel-item .content .functions .function-item .icon {
  width: 2.92682927vw;
  height: 2.92682927vw;
  margin-top: 3.52941176%;
  margin-bottom: 5%;
  object-fit: cover;
}
.panels .panel-item .content .functions .function-item .title {
  margin-bottom: 0.88235294%;
  font-size: 1.2195122vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}
.panels .panel-item .content .functions .function-item .desc {
  font-size: 0.85365854vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
}
.panels .panel-item .content .more {
  box-sizing: border-box;
  position: absolute;
  right: 1.8292682926829267vw;
  bottom: 1.5853658536585367vw;
  align-self: center;
  width: 7.317073170731707vw;
  height: 2.4390243902439024vw;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-size: 0.853658536585366vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.panels .panel-item .content .more:hover {
  background-color: #fff;
  color: #333;
}
@media screen and (min-width: 1640px) {
  h2.title {
    padding-top: 63px;
    margin-bottom: 8px;
    font-size: 36px;
  }
  h5.subtitle {
    margin-bottom: 24px;
    font-size: 18px;
  }
  .panels .panel-item {
    flex: 0 0 300px;
    width: 300px;
    height: 560px;
  }
  .panels .panel-item.active {
    flex: 1;
  }
  .panels .panel-item .role {
    left: 29px;
    bottom: 21px;
    font-size: 36px;
  }
  .panels .panel-item .content {
    padding: 33px 30px 0;
  }
  .panels .panel-item .content .title {
    font-size: 30px;
  }
  .panels .panel-item .content .functions {
    height: 360px;
  }
  .panels .panel-item .content .functions .function-item .icon {
    width: 48px;
    height: 48px;
  }
  .panels .panel-item .content .functions .function-item .title {
    font-size: 20px;
  }
  .panels .panel-item .content .functions .function-item .desc {
    font-size: 14px;
  }
  .panels .panel-item .content .more {
    bottom: 26px;
    right: 30px;
    height: 47px;
    font-size: 14px;
  }
}
@media screen and (max-width: 960px) {
  h2.title {
    padding-top: 36.875px;
    margin-bottom: 4.67188px;
    font-size: 21.0732px;
  }
  h5.subtitle {
    margin-bottom: 14.0469px;
    font-size: 10.5366px;
  }
  .panels .panel-item {
    flex: 0 0 175.61px;
    width: 175.61px;
    height: 327.8px;
  }
  .panels .panel-item.active {
    flex: 0 0 655.61px;
    width: 655.61px;
  }
  .panels .panel-item .role {
    left: 16.9688px;
    bottom: 12.2812px;
    font-size: 21.0732px;
  }
  .panels .panel-item .content {
    padding: 19.3125px 17.5469px 0;
  }
  .panels .panel-item .content .title {
    font-size: 17.561px;
  }
  .panels .panel-item .content .functions {
    height: 210.72px;
  }
  .panels .panel-item .content .functions .function-item .icon {
    width: 28.0938px;
    height: 28.0938px;
  }
  .panels .panel-item .content .functions .function-item .title {
    font-size: 11.7073px;
  }
  .panels .panel-item .content .functions .function-item .desc {
    font-size: 10px;
  }
  .panels .panel-item .content .more {
    bottom: 15.2195px;
    right: 17.561px;
    font-size: 10px;
  }
}
