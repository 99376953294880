

















.section-container {
  box-sizing: border-box;
  // height: calc(100vh - 90px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.wrap-saas {
    background-image: url(https://mktv-in-cdn.mockuai.com/16286534913535319.png);
  }
  &.wrap-supply {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238152069838910.png);
  }
  &.wrap-anchor {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238149059689781.png);
  }
  &.wrap-data {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238154825288660.png);
  }
}
.content-container {
  margin-top: 90px;
  box-sizing: border-box;
  width: 1640px;
  height: 810px;
  // outline: 1px solid red;
}

@media screen and (min-width: 960px) and (max-width: 1640px) {
  .content-container {
    width: 100vw;
    height: 49.390243902439025vw;
  }
}

@media screen and (max-width: 960px) {
  .section-container {
    overflow: auto;
  }
  .content-container {
    min-width: 960px;
    height: 474.1463414634146px;
  }
}
