.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.anchor-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.09756098%;
}
h2.title {
  padding-top: 4.30555556%;
  margin-bottom: 1.38888889%;
  text-align: center;
  line-height: 1.4;
  font-size: 2.43902439vw;
  font-weight: 600;
  color: #333;
}
.data-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 14.58333333%;
}
.data-box .data-item:last-child {
  margin-right: 0;
}
.data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.data-box .data-item .num-line .num {
  line-height: 1.14705882;
  font-size: 4.14634146vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #333;
}
.data-box .data-item .num-line .unit,
.data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.2195122vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333;
}
.data-box .data-item .num-line .unit {
  margin-bottom: 0.6097561vw;
}
.data-box .data-item .num-line .plus {
  align-self: center;
}
.data-box .data-item .text-line {
  color: #666;
  font-size: 1.2195122vw;
  font-weight: 400;
}
.anchor-swiper {
  position: relative;
  margin: 2.22222222% auto 0;
}
.anchor-swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3.65853659vw;
  height: 3.65853659vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.anchor-swiper .swiper-arrow.prev {
  left: -4.26829268vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16267704795807034.png);
}
.anchor-swiper .swiper-arrow.next {
  right: -4.26829268vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16267704795397316.png);
}
.anchor-swiper .anchor-item {
  position: relative;
  width: 16.58536585vw;
  height: 28.04878049vw;
  border-radius: 16px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
.anchor-swiper .anchor-item:hover .mask {
  visibility: hidden;
}
.anchor-swiper .anchor-item .mask {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 9, 42, 0.5);
}
.anchor-swiper .anchor-item .mask:hover {
  background: linear-gradient(180deg, rgba(0, 9, 42, 0) 0%, rgba(0, 9, 42, 0.77) 100%);
}
.anchor-swiper .anchor-item .mask.mask-hover {
  background: linear-gradient(180deg, rgba(0, 9, 42, 0) 0%, rgba(0, 9, 42, 0.77) 100%);
}
.anchor-swiper .anchor-item .main {
  width: 100%;
  object-fit: cover;
}
.anchor-swiper .anchor-item .info {
  box-sizing: border-box;
  position: absolute;
  bottom: 0.426829268292683vw;
  left: 0;
  width: 100%;
  min-height: 6.09756098vw;
  padding: 0 7.35294118%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.anchor-swiper .anchor-item .info .name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.79268293vw;
}
.anchor-swiper .anchor-item .info .name .icon {
  width: 1.2195122vw;
  height: 1.2195122vw;
  margin-right: 0.24390244vw;
}
.anchor-swiper .anchor-item .info .name .text {
  line-height: 1.4;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 1.2195122vw;
  font-weight: 500;
}
.anchor-swiper .anchor-item .info .fans {
  line-height: 1.4;
  font-size: 1.09756098vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
}
.anchor-swiper .anchor-item .info.info-hover {
  height: auto;
  align-items: flex-start;
}
.anchor-swiper .anchor-item .info.info-hover .desc {
  line-height: 1.42857143;
  font-size: 0.85365854vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
}
@media screen and (min-width: 1640px) {
  h2.title {
    font-size: 40px;
  }
  .data-box .data-item .num-line .num {
    font-size: 68px;
  }
  .data-box .data-item .num-line .unit,
  .data-box .data-item .num-line .plus {
    font-size: 20px;
  }
  .data-box .data-item .num-line .unit {
    margin-bottom: 10px;
  }
  .data-box .data-item .text-line {
    font-size: 20px;
  }
  .anchor-swiper .swiper-arrow {
    width: 60px;
    height: 60px;
  }
  .anchor-swiper .swiper-arrow.prev {
    left: -70px;
  }
  .anchor-swiper .swiper-arrow.next {
    right: -70px;
  }
  .anchor-swiper .anchor-item {
    width: 272px;
    height: 460px;
  }
  .anchor-swiper .anchor-item .info {
    height: 100px;
    padding: 0 20px;
  }
  .anchor-swiper .anchor-item .info .name {
    margin-bottom: 13px;
  }
  .anchor-swiper .anchor-item .info .name .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .anchor-swiper .anchor-item .info .name .text {
    font-size: 20px;
  }
  .anchor-swiper .anchor-item .info .fans {
    font-size: 18px;
  }
  .anchor-swiper .anchor-item .info.info-hover .desc {
    font-size: 14px;
  }
}
@media screen and (max-width: 960px) {
  h2.title {
    font-size: 23.4146px;
  }
  .data-box .data-item .num-line .num {
    font-size: 39.8049px;
  }
  .data-box .data-item .num-line .unit,
  .data-box .data-item .num-line .plus {
    font-size: 11.7073px;
  }
  .data-box .data-item .num-line .unit {
    margin-bottom: 5.85366px;
  }
  .data-box .data-item .text-line {
    font-size: 11.7073px;
  }
  .anchor-swiper .swiper-arrow {
    width: 35.109px;
    height: 35.109px;
  }
  .anchor-swiper .swiper-arrow.prev {
    left: -40.9756px;
  }
  .anchor-swiper .swiper-arrow.next {
    right: -40.9756px;
  }
  .anchor-swiper .anchor-item {
    width: 159.219px;
    height: 269.266px;
  }
  .anchor-swiper .anchor-item .info {
    height: 58.531px;
    padding: 0 11.703px;
  }
  .anchor-swiper .anchor-item .info .name {
    margin-bottom: 7.61px;
  }
  .anchor-swiper .anchor-item .info .name .icon {
    width: 11.688px;
    height: 11.688px;
    margin-right: 2.341px;
  }
  .anchor-swiper .anchor-item .info .name .text {
    font-size: 11.7073px;
  }
  .anchor-swiper .anchor-item .info .fans {
    font-size: 10.5366px;
  }
  .anchor-swiper .anchor-item .info.info-hover .desc {
    font-size: 10px;
  }
}
