











































































































































































.hide {
  display: none;
}
.section {
  position: relative;
  &:nth-child(1) {
    // background-color: rgba(0, 9, 42, .3);
    background-color: transparent;
    
  }
  &:nth-child(2) {
    background-color: #fff;
  }
  &:nth-child(3) {
    background-color: #00092a;
  }
  &:nth-child(4) {
    background-color: #fff;
  }
  &:nth-child(5) {
    background-color: rgba(0, 9, 42, 1);
  }
  &:nth-child(6) {
    // height: 200vh!important;
  }

  &.wrap-saas {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238093980913802.png);
  }
  &.wrap-supply {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238152069838910.png);
  }
  &.wrap-anchor {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238149059689781.png);
  }
  &.wrap-data {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238154825288660.png);
  }
}
.pt-90 {
  padding-top: 90px; 
}
